import { FC, useCallback, useEffect, useRef, useState } from 'react'
import ReactPlayer from 'react-player';
import { NavLink, useNavigate } from 'react-router-dom'
import { getContent } from '../actions/getContent'
import { ContentProps } from '../actions/props'
import { connectWS, sendWS } from '../actions/websocket';
import { config } from '../config';
import { ReactComponent as IconPlay } from './../assets/images/ui/play-button.svg';

import './Screensaver.scss'

interface ScreensaverProps {
    isCordova: boolean,
    isPlay?: boolean
}

const Screensaver: FC<ScreensaverProps> = ({ isCordova, isPlay }) => {

    const navigate = useNavigate();

    const [content, setContent] = useState<ContentProps[]>()
    const [videos, setVideos] = useState<string[]>([])

    const [videoUrl, setVideoUrl] = useState<string>('')
    const [urlPos, setUrlPos] = useState(0)
    const [playing, setPlaying] = useState(false)
    const [screenTime, setScreenTime] = useState(2)
    const [ws, setWS] = useState<any>(false)

    const setVideo = (video: ContentProps) => {
        const found = videos.find(url => url === video.url);
        if (found) {
            setVideos(videos.filter(url => url !== video.url));
        } else {
            setVideos(currentArray => [...currentArray, video.url])
        }
        // console.log(videos)
        if (videos.length === 0) {
            localStorage.setItem("videos", '')
        }

        // setVideos(videoArr)
    }

    const fetchData = useCallback(async () => {
        const data = await getContent();
        setContent(data);
        // console.log(data)
        if (localStorage["videos"] && localStorage["videos"] !== '') {
            // console.log('videoStorageSting', JSON.parse(localStorage["videos"]))
            let videosTemp = JSON.parse(localStorage["videos"])
            setVideos(videosTemp)

            if (isPlay && videosTemp.length > 0) {
                setVideoUrl('.' + videosTemp[urlPos])
                if (config.useWebSocket) {
                    sendWS(ws, videosTemp[urlPos])
                }
            }
        }
        if (localStorage["screenTime"] && localStorage["screenTime"] !== '') {
            setScreenTime(parseInt(localStorage["screenTime"]))
        }
    }, [isPlay, urlPos, ws])

    useEffect(() => {
        fetchData()
            .catch(console.error);;
    }, [fetchData])

    useEffect(() => {
        if (content && content?.length > 0) {
            localStorage.setItem("videos", JSON.stringify(videos.sort(function (a, b) {
                return a.localeCompare(b);
            })));
        }
    }, [content, videos])

    useEffect(() => {
        console.log('ws', config.useWebSocket)
        if (config.isClient && config.useWebSocket) {
            let ws = connectWS('screensaver', setVideoUrl)
            setWS(ws)
        }
    }, [])

    const player = useRef<ReactPlayer>(null);

    const handlePlay = () => {
        console.log('onPlay')
        setPlaying(true)
    }

    const handlePause = () => {
        console.log('onPause')
        setPlaying(false)
    }

    const handleEnded = () => {
        console.log('onEnded', videos, urlPos)
        if (videos && videos.length > 1 && urlPos + 1 < videos.length) {
            // setVideoUrl(videos[urlPos + 1])
            setVideoUrl('.' + videos[urlPos + 1])
            if (config.useWebSocket) {
                sendWS(ws, videos[urlPos + 1])
            }
            setUrlPos(urlPos + 1)
            setPlaying(true)
            return
        }

        if (videos && videos.length > 1 && urlPos + 1 === videos.length) {
            // setVideoUrl(videos[0])
            setVideoUrl('.' + videos[0])
            if (config.useWebSocket) {
                sendWS(ws, videos[0])
            }
            setUrlPos(0)
            setPlaying(true)
            return
        }

        setPlaying(true)

    }

    const incrementValue = () => {
        let newValue = screenTime + 1;
        if (newValue > 61) return;

        localStorage.setItem("screenTime", `${newValue}`)

        setScreenTime(newValue);
    }

    const decrementValue = () => {
        let newValue = screenTime - 1;
        if (newValue < 1) return;

        localStorage.setItem("screenTime", `${newValue}`)

        setScreenTime(newValue);
    }

    const getValue = () => {
        var text = ""
        switch (screenTime) {
            case 1:
                text = "min"
                break;
            default:
                text = "min"
        }
        // console.log(screenTime)
        // localStorage.setItem("screenTime", `${screenTime}`)

        return `${screenTime} ${text}`;
    }

    const handleOnInputChange = (evt: any) => {
        console.log(evt)
    }


    return (
        <div
            className={
                'screensaver '
                + ((isCordova) ? ' cordova ' : '')
            }
            onClick={
                () => {
                    if (videoUrl !== '') {
                        // console.log('back')
                        setVideoUrl('')
                        navigate(-1)
                    }
                }
            }
        >
            <div className={
                'logo '
                + ((videoUrl !== '') ? 'disabled' : '')
                + (config.isBottom ? ' bottom ' : '')
            }>
                <NavLink

                    to='/'
                >
                    <img src={require('./../assets/images/HO_Logo_rot.png')} alt='Hoval' width="100" />
                </NavLink>
            </div>
            <div className={'status ' + ((videoUrl !== '' || isPlay) ? 'disabled' : '')}>
                {
                    config.version
                } |
                {
                    (window.matchMedia('(display-mode: standalone)').matches || isCordova) ? ' App' : ' Web'
                }
            </div>
            <div className={
                'playlist ' + ((videoUrl !== '' || isPlay) ? 'disabled' : '')
                + ((config.isBottom) ? ' bottom ' : '')
            }>
                <div className='title'>
                    Screensaver
                    <IconPlay
                        className='play'
                        onClick={() => {
                            if (videos && videos.length > 0) {
                                // setVideoUrl(videos[urlPos])
                                setVideoUrl('.' + videos[urlPos])
                                if (config.useWebSocket) {
                                    sendWS(ws, videos[urlPos])
                                }
                            }
                        }}
                    />
                </div>

                <div className="input-stepper">
                    <button type="button" onClick={decrementValue}>-</button>
                    <input type="text" value={getValue()} onChange={handleOnInputChange} />
                    <button type="button" onClick={incrementValue}>+</button>

                    <input type="number" value={screenTime} step="1" style={{ visibility: 'hidden' }} onChange={handleOnInputChange} />
                </div>

                {
                    // .sort(function (a, b) {
                    //     return a.url.localeCompare(b.url);
                    // })
                    content ? content.map((folder: any, ckey) => (
                        <div key={ckey} className='check'>
                            <input
                                type={'checkbox'}
                                id={'l' + ckey}
                                onChange={() => setVideo(folder)}
                                checked={videos.find(url => url === folder.url) ? true : false}
                            /><label htmlFor={'l' + ckey}> {folder.title}</label>
                        </div>
                    )) : ''
                }
                {/* {
                    videos ? videos.map((video: any, ckey) => (
                        <div key={ckey}>
                            {video}
                        </div>
                    )) : 'xxx'
                } */}

            </div>
            <ReactPlayer
                ref={player}
                className={'react-player ' + ((videoUrl !== '') ? 'show' : '')}
                width='100%'
                height='100%'
                url={videoUrl}
                pip={false}
                playing={playing}
                controls={false}
                light={false}
                loop={false}
                muted={true}
                playsinline={true}
                onReady={() => {
                    console.log('onReady')
                    setPlaying(true)
                }}
                onStart={() => console.log('onStart')}
                onPlay={handlePlay}
                onPause={handlePause}
                onBuffer={() => console.log('onBuffer')}
                onEnded={handleEnded}
                onError={e => console.log('onError', e)}
            />
        </div>
    )

}

export default Screensaver

