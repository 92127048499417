import { FC, useCallback, useEffect, useState } from 'react'
import { getContent } from '../actions/getContent'
import { ContentProps } from '../actions/props'
import './ContentBrowser.scss'
import { ReactComponent as IconPlayRed } from './../assets/images/ui/play-button-red.svg';
import { connectWS, sendWS } from '../actions/websocket';
import { config } from '../config';

interface ContentBrowserProps {
    setVideo: Function,
    setTitle: Function,
    goTo: string[],
    setGoTo: Function,
    isFullscreen: boolean,
    setFullscreen: Function
}

interface FolderProps {
    path: string,
    title: string,
    subTitle?: string,
    children: FolderProps[],
    videos?: ContentProps[],
    thumb?: string
}

const ContentBrowser: FC<ContentBrowserProps> = ({ setVideo, setTitle, goTo, setGoTo, isFullscreen, setFullscreen }) => {

    // const [content, setContent] = useState<ContentProps[]>()
    const [folders, setFolders] = useState<FolderProps[]>()
    const [curFolder, setCurFolder] = useState<FolderProps>()
    const [curSubFolder, setCurSubFolder] = useState<FolderProps>()
    const [curVideo, setCurVideo] = useState<ContentProps>()
    const [loaded, setLoaded] = useState(false)

    const [ws, setWS] = useState<any>(false)

    const fetchData = useCallback(async () => {

        if (loaded) {
            return
        }

        const data = await getContent();
        // setContent(data);

        if (config.isClient && !loaded && config.useWebSocket) {
            let ws = connectWS('client')
            setWS(ws)
        }

        var folderObj: FolderProps[] = []
        let childrenObj: any[string] = []

        data.forEach((video: ContentProps) => {

            let pathArr = video.folderPath.split('/')

            if (pathArr.length > 0) {
                let path = pathArr[0]

                // console.log('#####1#', pathArr.length, path)

                // let children: FolderProps[] = []
                if (pathArr.length > 1) {

                    if (!childrenObj[path]) {
                        childrenObj[path] =
                            [{
                                path: pathArr[1],
                                title: (pathArr.length > 2) ? pathArr[1] : video.title,
                                children: [],
                                videos: [video],
                                thumb: video.thumb,
                                subTitle: video.subtitle
                            }]

                    } else {
                        let existsChild = childrenObj[path].filter((item: FolderProps) => item.path === pathArr[1]);
                        // console.log('#####2#', existsChild.length)
                        if (existsChild.length === 0) {
                            childrenObj[path].push(
                                {
                                    path: pathArr[1],
                                    title: (pathArr.length > 2) ? pathArr[1] : video.title,
                                    children: [],
                                    videos: [video],
                                    thumb: video.thumb,
                                    subTitle: video.subtitle
                                }
                            )
                        } else {
                            // console.log('#####3#', pathArr.length, existsChild[0], video)
                            // console.log(existsChild)

                            if (pathArr.length > 2) {
                                existsChild[0].videos.push(video)
                            } else {
                                childrenObj[path].push(
                                    {
                                        path: pathArr[1],
                                        title: video.title,
                                        children: [],
                                        videos: [video],
                                        thumb: video.thumb,
                                        subTitle: video.subtitle
                                    }
                                )
                            }
                        }
                    }

                }

                let exists = folderObj.filter(item => item.path === path).length > 0;
                if (!exists) {
                    folderObj.push(
                        {
                            path: path,
                            title: path,
                            children: childrenObj[path],
                            thumb: 'xxx',
                            subTitle: 'yyy'
                        }
                    )
                }

            }

            setFolders(folderObj)
            // console.log(folderObj)

        });

        setLoaded(true)
        // console.log(data)
    }, [loaded])

    useEffect(() => {
        fetchData()
            .catch(console.error);;
    }, [fetchData])



    useEffect(() => {

        const goHome = () => {
            setCurFolder(undefined)
            setCurSubFolder(undefined)
            setTitle([])
            setVideo([])
            setCurVideo(undefined)
            setGoTo([])
        }

        console.log(goTo, isFullscreen)
        if (goTo[0] === 'back') {

            // fullscreen
            if (isFullscreen) {
                setFullscreen(false)
                return
            }

            // subsub
            if (curVideo?.folderPath) {
                // console.log('subsub')
                setVideo([])
                setCurVideo(undefined)
                if (curSubFolder?.videos) {
                    setTitle([curFolder?.title, curSubFolder?.title])
                } else {
                    setTitle([curFolder?.title])
                }

                return
            }

            // sub
            if (curSubFolder?.videos) {
                // console.log('sub')
                setCurSubFolder(undefined)
                setTitle([curFolder?.title])
                return
            }
            // home
            if (curFolder?.children) {
                // console.log('home')
                goHome()
                return
            }
        }
        if (goTo[0] === 'home') {
            goHome()
            return
        }
        // if (goTo[0] === 'fullscreen') {
        //     setFullscreen(false)
        //     return
        // }
    }, [curFolder, curSubFolder, curVideo, goTo, isFullscreen, setFullscreen, setGoTo, setTitle, setVideo])



    // console.log('ContentBrowser')

    return (
        <div className={
            'content-browser '
            + ((curFolder?.children) ? 'small ' : 'big ')
            + (curFolder?.children && curSubFolder?.videos ? 'top ' : '')
            + (curVideo?.folderPath ? ' play-video' : '')
            + (config.isBottom ? ' bottom ' : '')
        }>

            <div className={
                'intro-buttons '
                + ((config.isTouch) ? ' touch ' : '')
            }>
                {
                    folders ? folders.sort(function (a, b) {
                        return a.title.localeCompare(b.title);
                    }).map((folder: any, fkey) => (
                        <div
                            key={fkey}
                            className={
                                'intro-button '
                                + ((curFolder?.path && curFolder?.path !== folder.path) ? 'hidden ' : '')
                            }
                            onClick={
                                () => {
                                    if (curFolder === folder) {
                                        setVideo([])
                                        setCurVideo(undefined)
                                        setCurSubFolder(curFolder !== folder ? folder : [])
                                    }
                                    setCurFolder(curFolder !== folder ? folder : [])
                                    setTitle(curFolder !== folder ? [folder.title] : [])
                                }
                            }
                        >
                            <div className='teaser'>
                                {
                                    <img alt={folder.title} src={
                                        (folder.path === 'Hoval')
                                            ? require('./../assets/images/Cover_HOVAL.jpg')
                                            : require('./../assets/images/Cover_Messe_24.jpg')
                                    } />
                                }
                            </div>
                            <div className='title'>
                                {folder.title}
                            </div>

                        </div>
                    )) : ''
                }
            </div>
            <div
                className={
                    'folder-buttons'
                }
            >
                {
                    // .sort(function (a, b) {
                    //     return a.title.localeCompare(b.title);
                    // }).
                    curFolder?.children ? curFolder.children.map((folder: any, fkey) => (
                        <div
                            key={fkey}
                            className={
                                'folder-button '
                                + ((curFolder?.children && curSubFolder?.videos && curSubFolder === folder) ? 'selected' : '')
                                + ((folder.videos.length === 1 && curVideo?.id === folder.videos[0].id) ? 'selected' : '')
                                + ((fkey === curFolder.children.length - 1) ? ' noborder' : '')
                            }
                            onClick={
                                () => {
                                    // console.log(folder)
                                    // Video direkt
                                    if (folder.videos.length === 1) {
                                        setVideo([folder.videos[0].url])
                                        setCurVideo(folder.videos[0])
                                        setTitle([curFolder.title, folder.videos[0].title])
                                        if (config.useWebSocket) {
                                            sendWS(ws, folder.videos[0].url)
                                        }
                                    } else {
                                        setCurSubFolder(curSubFolder !== folder ? folder : [])
                                        setTitle([curFolder.title, folder.title])
                                    }
                                }
                            }
                        >
                            <div className='thumb'>
                                {
                                    (folder.thumb && folder.thumb !== '') ? <img src={'.' + folder.thumb} width="100" alt={folder.title} /> : ''
                                }
                            </div>
                            <div className='title'>
                                <div className='title-play'>
                                    {folder.title}
                                    <IconPlayRed
                                        className={
                                            'play '
                                            + ((folder.videos.length === 1 && curVideo?.id === folder.videos[0].id) ? 'selected' : '')
                                        }
                                    />
                                </div>
                                <div className='subtitle'>
                                    {folder.subTitle}
                                </div>
                            </div>
                        </div>
                    )) : ''
                }
            </div>

            <div className='video-buttons'>
                {
                    // .sort(function (a, b) {
                    //     return a.title.localeCompare(b.title);
                    // }).
                    curFolder?.children && curSubFolder?.videos ? curSubFolder?.videos.map((video: ContentProps, vkey) => (
                        <div
                            key={vkey}
                            className={
                                'video-button '
                                + ((curVideo === video) ? 'selected' : '')
                            }
                            onClick={
                                () => {
                                    setVideo([video.url])
                                    if (config.useWebSocket) {
                                        sendWS(ws, video.url)
                                    }
                                    setCurVideo(video)
                                    setTitle([curFolder.title, curSubFolder.title, video.title])
                                }
                            }
                        >
                            <div className='thumb'>
                                <img src={'.' + video.thumb} alt={video.title} width="100" />
                            </div>
                            <div className='title'>
                                {video.title}
                                <div className='subtitle'>
                                    {video.subtitle}
                                </div>
                            </div>
                        </div>
                    )) : ''
                }
            </div>
        </div>
    );
}

export default ContentBrowser