import { FC, useEffect, useRef, useState } from 'react'
import ReactPlayer from 'react-player';
import { connectWS } from '../actions/websocket';
import { config } from '../config';
import './Videoplayer.scss'

const Screensaver: FC = () => {

    const [videoUrl, setVideoUrl] = useState<string>('')
    const [playing, setPlaying] = useState(false)
    const [, setWS] = useState<any>(false)

    useEffect(() => {
        console.log('ws')
        if (config.isClient) {
            let ws = connectWS('videoplayer', setVideoUrl)
            setWS(ws)
        }
    }, [])

    const player = useRef<ReactPlayer>(null);

    return (
        <div
            className={
                'videoplayer'
            }
        >
            <ReactPlayer
                ref={player}
                className={'react-player'}
                width='100%'
                height='100%'
                url={videoUrl}
                pip={false}
                playing={playing}
                controls={false}
                light={false}
                loop={false}
                muted={true}
                playsinline={true}
                onReady={() => {
                    console.log('onReady')
                    setPlaying(true)
                }}
                onStart={() => console.log('onStart')}
                // onPlay={handlePlay}
                // onPause={handlePause}
                onBuffer={() => console.log('onBuffer')}
                // onEnded={handleEnded}
                onError={e => console.log('onError', e)}
            />
        </div>
    )

}

export default Screensaver

