import { useRef, useState, FC, useEffect } from 'react'
import ReactPlayer from 'react-player'
import Duration from './Duration'
import './VideoPlayer.scss'

import { ReactComponent as IconPlay } from './../assets/images/ui/play-button.svg';
import { ReactComponent as IconPause } from './../assets/images/ui/pause-button.svg';
import { ReactComponent as IconFullscreen } from './../assets/images/ui/fullscreen.svg';
import { ReactComponent as IconMute } from './../assets/images/ui/mute.svg';
import { ReactComponent as IconVolume } from './../assets/images/ui/volume.svg';
import { ReactComponent as IconLoop } from './../assets/images/ui/loop.svg';
import { config } from '../config'

interface VideoPlayerProps {
    video?: string[],
    fullscreen: boolean,
    setFullscreen: Function
}

const VideoPlayer: FC<VideoPlayerProps> = ({ video, fullscreen, setFullscreen }) => {

    const [videoUrl, setVideoUrl] = useState<string>('')
    const [urlPos, setUrlPos] = useState(0)
    const [pip, setPip] = useState(false)
    const [playing, setPlaying] = useState(true)
    const [controls] = useState(false)
    const [light] = useState(false)
    const [volume, setVolume] = useState(0.8)
    const [muted, setMuted] = useState(true)
    const [played, setPlayed] = useState(0)
    const [loaded, setLoaded] = useState(0)
    const [duration, setDuration] = useState(0)
    const [playbackRate, setPlaybackRate] = useState(1.0)
    const [loop, setLoop] = useState(true)
    const [seeking, setSeeking] = useState(false)
    const hasAudio = false

    useEffect(() => {
        if (video && video.length > 0) {
            load(video[urlPos])
        } else {
            handleStop()
        }
    }, [urlPos, video])

    const load = (url: string) => {
        setVideoUrl('.' + url)
        setPlaying(true)
        setPlayed(0)
        setLoaded(0)
        setPip(false)
        // console.log(url)
    }

    const handlePlayPause = () => {
        setPlaying(!playing)
    }

    const handleStop = () => {
        setVideoUrl('')
        setPlaying(false)
    }

    const handleToggleLoop = () => {
        setLoop(!loop)
    }

    const handleVolumeChange = (e: any) => {
        setVolume(parseFloat(e.target.value))
    }

    const handleToggleMuted = () => {
        setMuted(!muted)
    }

    const handleOnPlaybackRateChange = (speed: any) => {
        setPlaybackRate(parseFloat(speed))
    }

    const handlePlay = () => {
        // console.log('onPlay')
        setPlaying(true)
    }

    const handleEnablePIP = () => {
        // console.log('onEnablePIP')
        setPip(true)
    }

    const handleDisablePIP = () => {
        // console.log('onDisablePIP')
        setPip(false)
    }

    const handlePause = () => {
        // console.log('onPause')
        setPlaying(false)
    }

    const handleSeekMouseDown = (e: any) => {
        setSeeking(true)
        // console.log(player)
    }

    const handleSeekChange = (e: any) => {
        // console.log(player)
        setPlayed(parseFloat(e.target.value))
    }

    const handleSeekMouseUp = (e: any) => {
        setSeeking(false)
        // console.log(player)
        if (player.current) {
            // console.log(parseFloat(e.target.value))
            player.current.seekTo(parseFloat(e.target.value))
        }
    }

    const handleProgress = (state: any) => {
        // console.log('onProgress', state)
        // We only want to update time slider if we are not currently seeking
        if (!seeking) {
            setPlayed(state.played)
            setLoaded(state.loaded)
            // this.setState(state)
        }
    }

    const handleEnded = () => {
        // console.log('onEnded', video, urlPos)
        if (video && video.length > 1 && urlPos + 1 < video.length) {
            setUrlPos(urlPos + 1)
            setPlaying(true)
            return
        }

        if (video && video.length > 1 && urlPos + 1 === video.length) {
            setUrlPos(0)
            setPlaying(true)
            return
        }

        setPlaying(loop)

    }

    const handleDuration = (duration: number) => {
        // console.log('onDuration', duration)
        setDuration(duration)
    }

    const handleClickFullscreen = () => {

        if (window.matchMedia('(display-mode: standalone)').matches) {
            setFullscreen(!fullscreen)
            return
        }

        console.log({ fullscreen })

        setFullscreen(!fullscreen)
        return
    }

    const player = useRef<ReactPlayer>(null);

    return (
        <div className='video-player'>
            <section className='video-content'>
                {
                    (videoUrl.length >= 0)
                        ?
                        <div
                            className={
                                'wrapper '
                                + ((fullscreen) ? 'fullscreen' : '')
                                + ((config.isTouch && !fullscreen) ? ' touch ' : '')
                            }
                            id="react-player"
                            onClick={handlePlayPause}
                        >
                            <ReactPlayer
                                ref={player}
                                className='react-player'
                                width='100%'
                                height='100%'
                                url={videoUrl}
                                pip={pip}
                                playing={playing}
                                controls={controls}
                                light={light}
                                loop={loop}
                                playbackRate={playbackRate}
                                volume={hasAudio ? volume : 0}
                                muted={muted}
                                playsinline={true}
                                // onReady={() => console.log('onReady')}
                                onStart={() => console.log('onStart')}
                                onPlay={handlePlay}
                                onEnablePIP={handleEnablePIP}
                                onDisablePIP={handleDisablePIP}
                                onPause={handlePause}
                                onBuffer={() => console.log('onBuffer')}
                                onPlaybackRateChange={handleOnPlaybackRateChange}
                                // onSeek={e => console.log('onSeek', e)}
                                onEnded={handleEnded}
                                onError={e => console.log('onError', e)}
                                onProgress={handleProgress}
                                onDuration={handleDuration}
                            />
                        </div>
                        : <div className='wrapper' id="react-player" />
                }
                <div className={
                    'controls '
                    + ((fullscreen) ? 'fullscreen' : '')
                    + ((config.isTouch) ? ' touch ' : '')
                }
                >
                    {
                        playing
                            ? <IconPause className='pause' onClick={handlePlayPause} />
                            : <IconPlay className='pause' onClick={handlePlayPause} />
                    }
                    {
                        loop
                            ? <IconLoop className='loop' onClick={() => handleToggleLoop()} />
                            : <IconLoop className='loop' style={{ opacity: 0.5 }} onClick={() => handleToggleLoop()} />
                    }
                    <input
                        className='pos'
                        type='range' min={0} max={0.999999} step='any'
                        value={played}
                        onMouseDown={handleSeekMouseDown}
                        onTouchStart={handleSeekMouseDown}
                        onChange={handleSeekChange}
                        onTouchEnd={handleSeekMouseUp}
                    />
                    <Duration
                        className='duration'
                        seconds={duration * (1 - played)}
                    />
                    {
                        hasAudio ?
                            <>
                                {
                                    muted
                                        ? <IconMute className='volume' onClick={() => handleToggleMuted()} />
                                        : <IconVolume className='volume' onClick={() => handleToggleMuted()} />
                                }

                                <input
                                    className='volume-slider'
                                    type='range'
                                    min={0}
                                    max={1}
                                    step='any'
                                    value={volume}
                                    onChange={handleVolumeChange}
                                />
                            </> : ''
                    }
                    <IconFullscreen className='fullscreen' onClick={handleClickFullscreen} />
                </div>

                <table className='stats'>
                    <tbody>
                        <tr>
                            <th>url</th>
                            <td className={!videoUrl ? 'faded' : ''}>
                                {(videoUrl ?? videoUrl) || 'null'}
                            </td>
                        </tr>
                        <tr>
                            <th>playing</th>
                            <td>{playing ? 'true' : 'false'}</td>
                        </tr>
                        <tr>
                            <th>volume</th>
                            <td>{volume.toFixed(3)}</td>
                        </tr>
                        <tr>
                            <th>speed</th>
                            <td>{playbackRate}</td>
                        </tr>
                        <tr>
                            <th>played</th>
                            <td>{played.toFixed(3)}</td>
                        </tr>
                        <tr>
                            <th>loaded</th>
                            <td>{loaded.toFixed(3)}</td>
                        </tr>
                        <tr>
                            <th>duration</th>
                            <td><Duration className='' seconds={duration} /></td>
                        </tr>
                        <tr>
                            <th>elapsed</th>
                            <td><Duration className='' seconds={duration * played} /></td>
                        </tr>
                        <tr>
                            <th>remaining</th>
                            <td><Duration className='' seconds={duration * (1 - played)} /></td>
                        </tr>
                    </tbody>
                </table>
            </section>
        </div>
    )
}

export default VideoPlayer