import React from 'react';
import ReactDOM from 'react-dom/client';
import './global.scss';
import {
  Routes,
  Route,
  HashRouter
} from "react-router-dom";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { config } from './config'
import Overview from './pages/Overview';
import Screensaver from './pages/Screensaver';
import VideoPlayer from './pages/Videoplayer';

declare global {
  interface Window { cordova: any; }
}
window.cordova = window.cordova || false;

let startApp = () => {
  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
  );
// HashRouter bei IOS!!!!
  root.render(
    <React.StrictMode>
      <HashRouter>
        <Routes>
          <Route path={`${config.path}/`} element={<Overview isCordova={(window.cordova)} />} />
          <Route path={`${config.path}/screensaver`} element={<Screensaver isCordova={(window.cordova)} />} />
          <Route path={`${config.path}/screensaver-play`} element={<Screensaver isCordova={(window.cordova)} isPlay={true} />} />
          <Route path={`${config.path}/videoplayer`} element={<VideoPlayer />} />
        </Routes>
      </HashRouter>
    </React.StrictMode>
  );
}

if (!window.cordova) {
  startApp()
  if (!config.isTouch) {
    serviceWorkerRegistration.register();
    console.log('register 1')
  }
} else {
  document.addEventListener('deviceready', () => {
    startApp();
    serviceWorkerRegistration.register();
    console.log('register 2')
  }, false);
}
