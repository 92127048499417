import { config } from './../config'

// Connect WS
export const connectWS = (app, setVideoUrl) => {
    const ws = new WebSocket(config.webSocket.url + ':' + config.webSocket.port);
    ws.onopen = function () {
        console.log("Websocket connected");
        ws.send(JSON.stringify({ page: app }));

    };
    ws.onclose = function (e) {
        console.log('Socket closed. Reconnect...', e.reason);
        this.timerId = setTimeout(function () {
            connectWS(app);
        }, 1000);
    };
    ws.onmessage = function (e) {

        console.log('Websocket Message:', app, e.data);
        let json = JSON.parse(e.data)

        if (json.video && json.video !== '' && app === 'videoplayer') {
            setVideoUrl('.' + json.video)
            return
        }

        return
    }
    ws.onerror = function (err) {
        console.error('Socket error: ', err.message, 'Closing socket');
        ws.close();
    };
    return ws
}

// Send to WS
export const sendWS = (ws, video) => {
    console.log('sendWS', video)
    if (ws && ws.readyState !== 0) {
        ws.send(JSON.stringify({ video }));
    }
}