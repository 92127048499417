// console.log(process.env)

const webserver = {
    url: (process.env.NODE_ENV === 'development')
        ? ''
        : '',
    path: ''
}

export const config = {
    appName: 'Hoval s2md',
    version: '20240417a',
    api: `https://hoval.s2md.de`,
    url: `${webserver.url}`,
    path: `${webserver.path}`,
    isClient: false, // client und remote
    isTouch: false, // 16:9
    webSocket: {
        url: 'ws://'
            + '192.168.188.58',
        port: 5001
    },
    useWebSocket: false,
    isBottom: false
};
