import { FC, useEffect, useState } from 'react'
import ContentBrowser from '../ui/ContentBrowser'
import VideoPlayer from './../ui/VideoPlayer'
import './Overview.scss'
import { ReactComponent as IconBack } from './../assets/images/ui/back.svg';
import { ReactComponent as IconRight } from './../assets/images/ui/right-arrow.svg';
import { ReactComponent as IconPlaylist } from './../assets/images/ui/playlist.svg';
import { NavLink, useNavigate } from 'react-router-dom';
import { config } from '../config';

interface OverviewProps {
    isCordova: boolean,
}

var screenTimer: any = undefined

const Overview: FC<OverviewProps> = ({ isCordova }) => {

    const [video, setVideo] = useState<string[]>([])
    const [title, setTitle] = useState<string[]>([])
    const [goTo, setGoTo] = useState<string[]>([])
    const [fullscreen, setFullscreen] = useState<boolean>(false)

    const navigate = useNavigate();

    // console.log('Overview')

    useEffect(() => {
        const mousedownListener = () => {
            if (screenTimer) {
                clearTimeout(screenTimer)
            }
            // console.log('screensaver mouse')
            screenTimer = setTimeout(
                () => {
                    console.log('scr start', (
                        (
                            localStorage && localStorage.getItem("screenTime"))
                            ? parseInt(localStorage.getItem("screenTime") ?? '1') * 60
                            : 60
                    ) * 1000)
                    if (localStorage["videos"] && localStorage["videos"] !== '') {
                        let videosTemp = JSON.parse(localStorage["videos"])
                        if (videosTemp.length > 0) {
                            navigate('screensaver-play')
                        }
                    }
                },
                (
                    (
                        localStorage && localStorage.getItem("screenTime"))
                        ? parseInt(localStorage.getItem("screenTime") ?? '1') * 60
                        : 60
                ) * 1000
            )
        }
        mousedownListener()
        document.addEventListener('mousedown', mousedownListener, true);

        return () => document.removeEventListener("mousedown", mousedownListener);
    }, [navigate])

    return (
        <div
            className={
                'overview '
                + (fullscreen ? 'fullscreen' : '')
                + ((isCordova && !fullscreen) ? ' cordova ' : '')
            }
        >
            <div>
                <div
                    className={
                        'logo'
                        + (config.isTouch ? ' touch ' : '')
                        + (config.isBottom ? ' bottom ' : '')
                    }
                    onClick={() => { setGoTo(['home']) }}
                >
                    <img src={require('./../assets/images/HO_Logo_rot.png')} alt='Hoval' width="100" />
                </div>
            </div>
            <div
                className={
                    'header '
                    + (
                        title.length === 1
                            ? 'big'
                            : (
                                (title.length === 2 && video.length === 0)
                                    ? 'small'
                                    : ((title.length === 3 || video.length > 0) ? 'smaller' : '')
                            )
                    )
                    + (config.isTouch ? ' touch ' : '')
                    + (config.isBottom ? ' bottom ' : '')
                }
                onClick={
                    () => {
                        if (fullscreen) {
                            setGoTo(['fullscreen', title.join('-')])
                        } else {
                            setGoTo(['back', title.join('-')])
                        }
                        setFullscreen(false)
                    }
                }
            >
                {
                    title.length > 0 ? <IconBack className='back' /> : ''
                }
                <div className='title'>
                    {
                        title.map((str: any, skey) => (
                            <div key={skey} className="str">{str}
                                {
                                    (skey + 1 < title.length) ? <IconRight className='right' height={20} /> : ''
                                }</div>


                        ))
                    }
                </div>
            </div>
            <div
                className={
                    'content '
                    + ((fullscreen) ? 'fullscreen' : '')
                    + (config.isTouch ? ' touch ' : '')
                }
            >
                <ContentBrowser
                    setVideo={setVideo}
                    setTitle={setTitle}
                    goTo={goTo}
                    setGoTo={setGoTo}
                    isFullscreen={fullscreen}
                    setFullscreen={setFullscreen}
                />

                <div className={
                    'video '
                    + ((video.length === 0) ? 'disabled ' : '')
                    + ((isCordova) ? ' cordova ' : '')
                    + ((fullscreen) ? 'fullscreen' : '')
                    + (config.isTouch ? ' touch ' : '')
                    + (config.isBottom ? ' bottom ' : '')
                }>
                    <VideoPlayer video={video} fullscreen={fullscreen} setFullscreen={setFullscreen} />
                </div>

            </div>
            <div className='footer'>
                {
                    (window.matchMedia('(display-mode: standalone)').matches)
                        ? <div>
                            <NavLink to={'screensaver'} >
                                <IconPlaylist className={'playlist ' + ((title.length > 0) ? 'disabled' : '')} />
                            </NavLink>
                        </div>
                        : <div>
                            <NavLink to={'screensaver'} >
                                <IconPlaylist className={'playlist ' + ((title.length > 0) ? 'disabled' : '')} />
                            </NavLink>
                        </div>
                }
            </div>
        </div>
    );
}

export default Overview
