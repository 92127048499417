import { config } from '../config'
import { ContentProps } from './props'
import content from './../assets/content/content.json'

const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'POST,OPTIONS',
}

export const getContent = async () => {

    // var url = `${config.api}/content/content.php`

    if (config.isTouch) {
        return content.data
    }

    var url = `./content/content.json`

    var options: RequestInit = {
        method: 'GET',
        headers: headers,
        mode: 'cors'
    }

    const response = await fetch(url, options).then(response => {
        return response.json()
    }).catch((error) => {
        return content
        // return { error: true, text: `${error}` }
    })

    const data = await response;

    if (!data.data) {
        console.log('getContent Error:', data)
        return data
    }

    return data.data as ContentProps[]
}
